import moment from 'moment'
import { useEffect, useState } from 'react'
import { Link, useOutletContext } from 'react-router-dom'
import { BoxAlert } from '../../../components/Alerts'
import { addressInputs, localities } from '../../../data/inputs'
import { ArrowCalendar } from '../AppointmentSchedule'
import obras from '../AppointmentSchedule/obras.json'
import Resume from '../PatientForm/Resume'
import FilesSection from './FilesSection'
import { useInsuranceForm } from './hook'

const InsuranceSection = ({data: {formik, addFileToOrders, orders, deleteOrder, generalData}}) => {
  const {values, errors, handleChange, setFieldValue} = formik
  const [isParticular, setIsParticular] = useState(0)

  useEffect(() => {
    setIsParticular(parseInt(values?.healthInsurance?.id))
  }, [values?.healthInsurance])

  const healthInsurances = obras.data.os
    .filter(o => o.name.toLocaleLowerCase() !== "particular")
    .sort((a, b) => a.name.localeCompare(b.name))

  const particular = obras.data.os[0]

  const setInsurance = (e) => {
    const insurance = obras.data.os.find(o => o.id === parseInt(e.target.value))
    if(insurance) setFieldValue('healthInsurance', {...insurance})
  }

  return (
    <>
      <div>
        <h4 className='font-bold uppercase text-lg'>Tipo de cobertura</h4>
        <p className='text-sm opacity-60'>Elegí entre particular o tu obra social/prepaga</p>
      </div>
      <div className='min-h-[60px]'>
        <fieldset className={`h-14 flex flex-col gap-1 bg-green-10 max-w-[400px]`}>
          <select 
            name="healthInsurance"
            onChange={setInsurance}
            value={values?.healthInsurance?.id}
            className={`pb-1 outline-none w-full text-darkBlue placeholder:text-darkBlue opacity-60`}
          >
            <option value={""}>Seleccioná una opción</option>
            <option value={particular?.id}>{particular?.name}</option>
            {healthInsurances.map((o, i) => <option key={i} value={o.id}>{o.name}</option>)}
          </select>
          <div className={`flex flex-1 items-center text-xs text-red-400 bg-blue-10 border-t ${errors?.healthInsurance ? 'border-error' : ''}`}>
            {errors?.healthInsurance && errors?.healthInsurance}
          </div>
        </fieldset>     
        {isParticular > 0 && <FilesSection data={{formik, addFileToOrders, orders, deleteOrder, ...generalData}} />}
        {isParticular == 1 &&
          <BoxAlert data={{
            type: "warning", 
            description: "Las visitas particulares serán abonadas en su totalidad por los pacientes",
            extraContainerStyles: "!mt-2"
          }} />
        }
        </div>
    </>
  )
}

// "streetName": "500",
// "streetNumber": "905",
// "city": "Manuel B.Gonnet",
// "streetDescription": "e/ 3 y 4"

const AddressSection = ({data: {formik}}) => {
  const {values, errors, handleChange} = formik

  
  return (
    <div className=''>
      <div>
        <h4 className='font-bold uppercase text-lg'>Domicilio</h4> 
      </div>
      <p className='text-sm opacity-60 -mt-1'>Las extracciones a domicilio son únicamente en la ciudad de La Plata</p>
      <div className='grid md:grid-cols-2 gap-1'>
        {addressInputs.map((input, index) => (
          <label key={index} className='max-w-[400px] mt-2 pr-4'>
            <legend className='text-sm opacity-90'>{input.title}</legend>
            <input 
              name={input.name}
              type={input.type}
              onChange={handleChange}
              value={values[input.name]}
              className='border-b py-2 pl-1 border-deactivatedDarkBlue placeholder:text-darkBlue opacity-60 outline-none w-full'
              placeholder={input.placeholder}
              disabled={input.disabled}
            />
            <div className={`flex flex-1 h-5 items-center text-xs text-red-400 bg-blue-10 border-t ${errors[input.name] ? 'border-error' : ''}`}>
              {errors[input.name] && errors[input.name]}
            </div>
          </label>
        ))}
        <label className='max-w-[400px] mt-2 pr-4'>
          <legend className='text-sm opacity-90'>Localidad</legend>
          <select 
            name="city"
            onChange={handleChange}
            value={values.city}
            className='border-b py-2 pl-1 border-deactivatedDarkBlue placeholder:text-darkBlue opacity-60 outline-none w-full'
          >
            <option value="">Seleccionar</option>
            {localities.map((locality, index) => <option key={index}>{locality}</option>)}
          </select>
          <div className={`flex flex-1 h-5 items-center text-xs text-red-400 bg-blue-10 border-t ${errors.city ? 'border-error' : ''}`}>
            {errors.city && errors.city}
          </div>
        </label>
      </div>
    </div>
  )
}

const InsuranceForm = () => {
  const generalData = useOutletContext()
  const {
    appointment,
    isDomi, 
    formik,
    addFileToOrders,
    orders,
    deleteOrder
  } = useInsuranceForm(generalData.files, generalData.setFiles)

  const hour = appointment?.hour?.hour ? appointment?.hour?.hour : "00"
  const minute = appointment?.hour?.minute ? appointment?.hour?.minute : "00"
  
  const preDate = moment(new Date(appointment?.date))
  const date = `${preDate.format('dddd D')} de ${preDate.format('MMMM')}`
  const isGorina = appointment?.shop?.value.includes('GORINA');
  const gap = isGorina
  ? preDate.day() === 6 // Si es sábado
    ? 3 // 3 horas para GORINA los sábados
    : 4 // 4 horas para GORINA en otros días
  : 4; // 4 horas para las demás tiendas

  const time = isGorina 
  ? `Entre ${hour}:${minute} hs - ${parseInt(hour) + gap}:${minute} hs` 
  : `${hour}:${minute} hs`;
  const type = appointment?.shop.value
  //console.log(appointment)
  const direccionGorina = "Calle 485 N° 4685 Esq. 138" //appointment?.shop?.place.address + " - " + appointment?.shop?.place.city


  return (
    <div className='mx-auto max-w-max md:pb-10 lg:pb-0'>
      <div className='mb-4'>
        <Link to="/agenda/paciente" className='flex items-center justify-start gap-2 max-w-max'>
          <ArrowCalendar left color="fill-cyan h-3" /> <p className='text-md font-bold text-cyan opacity-60'>Volver</p>
        </Link>
        <>
          {isDomi 
            ? (
              <h1 className='font-bold text-[25px]  md:text-[28px] xl:text-4xl mt-4'>
                Completá <span className='text-cyan'>tu domicilio</span> y los datos de <span className='text-cyan'>tu cobertura médica:</span>
              </h1>   
            )
            : (
              <h1 className='font-bold text-[25px]  md:text-[28px] xl:text-4xl mt-4'>
                Completá los datos de <span className='text-cyan'>tu cobertura médica:</span>
              </h1>
            )
          }
        </>
      </div>
      <div className='flex gap-4 flex-col lg:flex-row w-full'>
        <Resume data={{
          test: appointment?.test?.name,
          location: isDomi ? "En tu domicilio" : appointment?.shop?.value.includes('SUC13') ? 'Av. 13 N° 215 - La Plata' : appointment?.shop?.value.includes('SUC14') ? 'Av. 14 N° 280 - La Plata':direccionGorina,
          date,
          time,
          type
        }} />
        <form 
          className='flex-1 min-w-full md:min-w-[700px] md:max-w-[700px] space-y-4' 
          onSubmit={formik.handleSubmit}
        >
          <div className='flex-1 bg-white shadow-2 rounded-lg py-5 px-5 md:px-7 min-h-[310px] space-y-4'>
            {isDomi && <AddressSection data={{formik}} />}
            <InsuranceSection data={{formik, addFileToOrders, orders, deleteOrder, generalData}} />
          </div>
          <div className='w-full flex md:justify-between gap-4 pb-5 md:pb-10'>
            <Link
              to="/agenda/paciente"
              className='w-full flex-1 lg:max-w-max border border-cyan text-cyan py-4 px-6 400:px-12 rounded-lg flex-1 opacity-70 md:flex-0 text-center'
            >
              Volver
            </Link>
            <button
              type="submit"
              className='w-full lg:max-w-max text-white bg-cyan shadow-6 py-4 px-6 400:px-11 rounded-lg flex-1 md:flex-0'
            >
              Continuar
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default InsuranceForm
